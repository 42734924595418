import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil'
import Realisations from '../views/Realisations'
import Contact from '../views/Contact'
import PageNotFound from '../views/PageNotFound'
import Mentions from '../views/Mentions'
import Brigade from '../views/Realisations/Brigade'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/realisations',
    name: 'Realisations',
    component: Realisations
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/mentions',
    name: 'Mentions legales',
    component: Mentions
  },
  {
    path: '/realisations/brigade',
    name: 'Brigade project',
    component: Brigade
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
