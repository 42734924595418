<template>
  <div id="header-parallax">
    <header>
      <h1 class="medium-title" id="title-site">Jolan</h1>
      <img
        src="@/assets/header-parallax/normal/perso.svg"
        id="perso"
        class="translate-scroll"
        data-speed="-0.15"
        alt="Personnage"
      />
      <img
        src="@/assets/header-parallax/normal/tree1.svg"
        id="tree1"
        class="translate-scroll"
        data-speed="0.2"
        alt="Sapins"
      />
      <img
        src="@/assets/header-parallax/normal/tree2.svg"
        id="tree2"
        class="translate-scroll"
        data-speed="0.4"
        alt="Sapins"
      />
      <img
        src="@/assets/header-parallax/normal/mountain.svg"
        id="mountain"
        class="translate-scroll"
        data-speed="0.5"
        alt="Montagne"
      />
      <img
        src="@/assets/header-parallax/normal/sky.svg"
        id="sky"
        class="translate-scroll"
        data-speed="0.8"
        alt="Ciel"
      />
    </header>

    <div class="shadow-container">
      <div class="shadow"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: "HomeTop",
  data() {
    return {
      headerAnimation: true,
      scroll: function () {
        window.addEventListener("scroll", () => {
          if (document.getElementById("header-parallax") != undefined) {
            //Pour éviter l'appel de cette fonction dans les autres pages
            var header = document.querySelector("header");
            var header_height = header.offsetHeight;
            var scroll = window.pageYOffset;

            if (scroll <= header_height) {
              const titleSite = document.getElementById("title-site");
              const translate = document.querySelectorAll(".translate-scroll");

              titleSite.style.transform = `translate(-50%, ${scroll}px)`;
              titleSite.style.opacity = -scroll / (header_height / 2.5) + 1;

              translate.forEach((element) => {
                let speed = element.dataset.speed;
                element.style.transform = `translateY(${scroll * speed}px)`;
              });
            }
          }
        });
      },
    };
  },
  mounted() {
    if (document.getElementById("header-parallax") != undefined) {
      this.scroll();
    }
  },
  unmounted() {},
};
</script>



<style lang="scss" scoped>
@import "../scss/components/home_top.scss";
</style>