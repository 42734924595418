<template>
  <div class='realisations-content'>
    <div class="real real1 large-container">
      <ul class="navbar reveal">
      </ul>
      <div class="real-header">
        <div id="mountain-rea" class="reveal"></div>
        <h1 id="medium-title-realisations" class="medium-title reveal">Réalisations</h1>
      </div>
    </div>
    <div class="large-container">
      <comon></comon>
      <brigade></brigade>
      <ckoa></ckoa>
      <weather></weather>
      <biere></biere>
    </div>
  </div>
</template>


<script>
import Comon from './realisations/Comon'
import Brigade from './realisations/Brigade'
import Ckoa from './realisations/Ckoa'
import Weather from './realisations/Weather'
import Biere from './realisations/Biere'

  export default {
    name: 'RealisationsContent',
    components: {
      'comon': Comon,
      'brigade': Brigade,
      'ckoa': Ckoa,
      'weather': Weather,
      'biere': Biere
    },
    mounted: function() {
      
    }
  }

</script>

<style lang="scss">
@import '../scss/components/realisations_content.scss';
@import '../scss/components/realisations_animations.scss';

</style>