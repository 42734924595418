<template>
  <div class="real real3">
    <ul class="navbar reveal"></ul>
    <div class="real-infos">
      <h1 class="medium-title medium reveal">Brigade Fantôme</h1>
      <div class="rea-content">
        <div>
          <div class="line-rea"></div>
          <div class="description-rea reveal">Site Web <br />(Wordpress)</div>
          <router-link to="/realisations/brigade" class="button">
            Voir le projet
          </router-link>
        </div>
        <router-link to="/realisations/brigade" class="img-rea reveal">
        </router-link>
      </div>
      <div class="primary-title reveal">02</div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Brigade",
};
</script>

<style lang="scss">
</style>