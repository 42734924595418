<template>
  <div>
    <footer>
      <div class="buttons">
        <router-link to="/">
          <a class="button">Accueil</a>
        </router-link>
        <a><div @click="jump" class="logo"></div></a>
        <router-link to="/realisations">
          <a class="button contact-btn">Mes réalisations</a>
        </router-link>
      </div>
      <div class="mentions_link">
        <p>Copyright © 2021 - Jolan Callens</p>
        <router-link to="/mentions">
          <a>Mentions légales</a>
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import jump from "jump.js";

export default {
  name: "Footer",
  methods: {
    jump: function () {
      jump("html");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/footer.scss";
</style>