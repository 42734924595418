<template>

  <div class="section competences-container">

    <section id="competences">

      <h1 class="primary-title" id="anim-title-competences">COMPÉTENCES</h1>
      
      <div class="medium-container">
        <div class="competences-content">

          <ul class="realisations-icons">
            <li>
              <div class="onglet-icon" id="onglet-dev">
                <div class="white-circle" @click="toggleDev">
                  <div class="category-icon dev-icon"></div>
                </div>
              </div>
            </li>

            <li>
              <div class="onglet-icon" id="onglet-video">
                <div class="white-circle" @click="toggleVideo">
                  <div class="category-icon video-icon"></div>
                </div>
              </div>
            </li>

            <li>
              <div class="onglet-icon" id="onglet-graph">
                <div class="white-circle" @click="toggleGraph">
                  <div class="category-icon graph-icon"></div>
                </div>
              </div>
            </li>
          </ul>

          <div class="competences-card" id="competences-card">
            <competence-dev v-bind:animation="animation" v-if="developpement"></competence-dev>
            <competence-video v-bind:animation="animation" v-if="video"></competence-video>
            <competence-graph v-bind:animation="animation" v-if="graphisme"></competence-graph>
          </div>

        </div>
      </div>

    </section>

  </div>

</template>



<script>

import { gsap } from 'gsap';
import CompetenceDev from './competences-categorys/Competence-dev';
import CompetenceVideo from './competences-categorys/Competence-video';
import CompetenceGraph from './competences-categorys/Competence-graph';

  export default {
    name:'Competences',
    data() {
      return {
        developpement: true,
        video: false,
        graphisme: false,
        // Fonction qui va être envoyé aux composants enfants
        animation: function() {
          const icon = document.querySelectorAll('.icon');
          const infos = document.querySelectorAll('.competence-info');
          const progressBar = document.querySelectorAll('.competence-info::before');
          var timeline = gsap.timeline();
          timeline.staggerFrom(icon, 0.5, {backgroundSize: '0'}, 0.1, 0.1);
          timeline.staggerFrom(infos, 0.5, {opacity: '0'}, 0.1, `-=${icon.length / 10}`);
          timeline.staggerFrom(progressBar, 0.5, {opacity: '0'}, 0.5, `-=${icon.length / 10}`);
          return timeline;
        }
      }
    },
    components: {
      'competence-dev': CompetenceDev,
      'competence-video': CompetenceVideo,
      'competence-graph': CompetenceGraph
    },
    methods: {
      toggleDev: function() {
        this.video = false;
        this.graphisme = false;
        let card = document.getElementById('competences-card');
        card.style.height = '850px';

        if(this.developpement == false) {
          this.developpement = true;
          this.animationOnglet();
        }

        let ongletDev = document.getElementById('onglet-dev');
        let ongletVideo = document.getElementById('onglet-video');
        let ongletGraph = document.getElementById('onglet-graph');
        ongletDev.style.backgroundSize = '100%';
        ongletVideo.style.backgroundSize = '0px';
        ongletGraph.style.backgroundSize = '0px';

      },
      toggleVideo: function() {
        this.developpement = false;
        this.graphisme = false;
        let card = document.getElementById('competences-card');
        card.style.height = '720px';

        if(this.video == false) {
          this.video = true;
          this.animationOnglet();
        }

        let ongletDev = document.getElementById('onglet-dev');
        let ongletVideo = document.getElementById('onglet-video');
        let ongletGraph = document.getElementById('onglet-graph');
        ongletVideo.style.backgroundSize = '100%';
        ongletDev.style.backgroundSize = '0px';
        ongletGraph.style.backgroundSize = '0px';
      },
      toggleGraph: function() {
        this.developpement = false;
        this.video = false;
        let card = document.getElementById('competences-card');
        card.style.height = '600px';
        
        if(this.graphisme == false) {
          this.graphisme = true;
          this.animationOnglet();
        }

        let ongletDev = document.getElementById('onglet-dev');
        let ongletVideo = document.getElementById('onglet-video');
        let ongletGraph = document.getElementById('onglet-graph');
        ongletGraph.style.backgroundSize = '100%';
        ongletVideo.style.backgroundSize = '0px';
        ongletDev.style.backgroundSize = '0px';
      },
      animationOnglet: function() {
        var tl = gsap.timeline();
        tl.from('#competences-card', 0.7, {width: '0'});
      }
    }
  }


</script>


<style lang="scss">

@import '../scss/components/competences.scss';

</style>