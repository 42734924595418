
<template>

  <div class="section">

    <section id="parcours" class="animation-section">

      <h1 class="primary-title" id="anim-title-parcours">PARCOURS</h1>

      <div class="parcours-container">
        <div class="image-mmi">

          <div id="box-container-mmi">
            <div class="box" id="box-mmi">
              <div class="box-part2"></div>

              <div class="content-box">
                <h2 class="secondary-title animation-text1">DUT MMI</h2>
                <h3 class="text-information animation-text2">2019 - 2021</h3>
                <p class="paragraphe animation-text3">Le DUT MMI propose des compétences très <span class="information-important">polyvalentes</span>. Il est orienté vers les métiers du <span class="information-important">multimédia</span> et permet d’aborder de nombreux domaines tels que la <span class="information-important">Programmation</span>, l’<span class="information-important">Audiovisuel</span>, le <span class="information-important">Graphisme</span> et la <span class="information-important">Communication</span>. Il nous fait découvrir le monde <span class="information-important">professionnel</span> avec de vrais projets clients et un stage de deux mois.</p>
              </div>

            </div>
          </div>

          <div class="image-mmi-graph"></div>
        </div>

        <div class="image-mist-container">
          <div class="image-mist">

            <div id="box-container-mist">
              <div class="box" id="box-mist">
                <div class="box-part2"></div>

                <div class="content-box">
                  <h2 class="secondary-title animation-text4">L1 MIST</h2>
                  <h3 class="text-information animation-text5">2018 - 2019</h3>
                  <p class="paragraphe animation-text6">La licence Mathématiques Informatique Sciences et Technologies permet d’enseigner la <span class="information-important">logique</span> et les fondamentaux des sciences du <span class="information-important">numérique</span>. Elle aborde les bases de la <span class="information-important">programmation</span>, de l’<span class="information-important">informatique</span> en général, avec un programme poussé en <span class="information-important">mathématiques</span>.</p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


    </section>

  </div>

</template>

<script>

export default {
  name: 'Parcours',
}
</script>


<style lang="scss" scoped>
@import '../scss/components/parcours.scss';

</style>