<template>
  <div class="competence-card">
    <div class="competence-category competence-category-dev">
      <ul>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">HTML / CSS</p>
              <p class="paragraphe">90%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">JAVASCRIPT</p>
              <p class="paragraphe">70%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">VUE JS</p>
              <p class="paragraphe">60%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">PHP</p>
              <p class="paragraphe">50%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">SWIFT</p>
              <p class="paragraphe">50%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
        <li>
          <div class="content_responsive">
            <div class="icon"></div>
            <div class="competence-info">
              <p class="paragraphe">WORDPRESS</p>
              <p class="paragraphe">50%</p>
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="progress-bar"></div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: "CompetenceDev",
  props: ["animation"],
  mounted() {
    this.animation();
  },
};
</script>

<style lang="scss">
</style>