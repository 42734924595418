<template>
  <div id="nav">
    <div class="loader">
      <div class="logo"></div>
      <div class="lettres">
        <span class="lettre">C</span>
        <span class="lettre">H</span>
        <span class="lettre">A</span>
        <span class="lettre">R</span>
        <span class="lettre">G</span>
        <span class="lettre">E</span>
        <span class="lettre">M</span>
        <span class="lettre">E</span>
        <span class="lettre">N</span>
        <span class="lettre">T</span>
      </div>
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    loader: function() {
      const loader = document.querySelector('.loader')
      window.addEventListener('load', () => {
        loader.classList.add('loader-unvisible')
        setTimeout(() => {
            loader.style.display = 'none'
          }, 400);
      })
    }
  },
  mounted: function() {
    this.loader()
  }
}
</script>

<style lang="scss">
@import './scss/variables.scss';
@import './scss/style.scss';
</style>
