<template>
  <div class="home">
    <nav-menu></nav-menu>
    <home-top></home-top>
    <a-propos></a-propos>
    <parcours></parcours>
    <competences></competences>
    <my-footer></my-footer>
  </div>
</template>

<script>

import ScrollReveal from 'scrollreveal'



import NavMenu from '../components/Nav-menu';
import HomeTop from '../components/Home-top';
import APropos from '../components/A-propos';
import Parcours from '../components/Parcours';
import Competences from '../components/Competences';
import Footer from '../components/Footer';

//GSAP et scrollTrigger
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Accueil',
  components: {
    'nav-menu': NavMenu,
    'home-top': HomeTop,
    'a-propos': APropos,
    'parcours': Parcours,
    'competences': Competences,
    'my-footer': Footer
  },
  mounted: function() {
      this.startAnimations()
      this.reveal()
  },
  methods: {

    //Animations avec GSAP
    startAnimations: function() {

      //////////////////
      //TITRE A PROPOS
      //////////////////

      let tlapropos = gsap.timeline({
        scrollTrigger: {
          trigger: ".shadow",
          start: "top bottom", // quand le haut du trigger touche le bas du viewport
          end: "+=170%;", // fin 1.7 hauteur d'écran après
          scrub: 0
        }
      });

      // ("element à modifier", durée par rapport à la durée totale, {style à modifier}, instant de départ)
      tlapropos.to("#anim-title-apropos", 2, {left: '28%', opacity: '0.8'}, 1)


      //////////////////
      //TITRE PARCOURS
      //////////////////

      let tlTitleparcours = gsap.timeline({
        scrollTrigger: {
          trigger: "#a-propos",
          start: "bottom bottom",
          end: "+=170%;", 
          scrub: 0
        }
      });

      tlTitleparcours.to("#anim-title-parcours", 2, {left: '-=35%', opacity: '0.8'}, 0.5)


      //////////////////
      //ANIATIONS PARCOURS
      //////////////////

      let tlparcours = gsap.timeline({
          scrollTrigger: {
            trigger: ".parcours-container",
            pin: true,
            scrub: 0.2,
            start: "bottom bottom", //commence lorsque son sommet touche le haut de page
            end: "+=4000vh",
          }
        });

      tlparcours.from(".image-mmi", 1, {width:'63%', height:"50%"},0) //aggrandissement image
      tlparcours.from(".image-mmi", 1.5, {left: '0'}, 0) 
      tlparcours.to("#box-container-mmi", 0.3, {opacity: '1'}, 1.5) 
      tlparcours.to(".image-mmi-graph", 0.9, {opacity: '1'}, 1.8) 
      tlparcours.to(".animation-text1", 0.3, {opacity: '1'}, 2)
      tlparcours.to(".animation-text2", 0.3, {opacity: '1'}, 2.2)
      tlparcours.to(".animation-text3", 0.3, {opacity: '1'}, 2.4)

      tlparcours.to(".image-mist-container", 1.5, {height: '100vh'}, 3.5) //Image 2 recouvre image 1 par dessus
      tlparcours.to(".image-mmi", 0.1, {display: 'none'},4.7)
      tlparcours.to(".image-mist-container", 1.6, {left: '-35%'}, 4.8) 
      tlparcours.to("#box-container-mist", 0.3, {opacity: '1'}, 6.4) 
      tlparcours.to(".animation-text4", 0.3, {opacity: '1'}, 6.8)
      tlparcours.to(".animation-text5", 0.3, {opacity: '1'}, 6.9)
      tlparcours.to(".animation-text6", 0.5, {opacity: '1.5'}, 7.2)
      tlparcours.to(".animation-text6", 1, {opacity: '1.5'}, 7.7)


      //////////////////
      //TITRE A PROPOS
      //////////////////

      let tlTitleCompetences = gsap.timeline({
        scrollTrigger: {
          trigger: ".competences-container",
          start: "top bottom", // quand le haut du trigger touche le bas du viewport
          end: "+=170%;", // fin 1.7 hauteur d'écran après
          scrub: 0,
        }
      });

      // ("element à modifier", durée par rapport à la durée totale, {style à modifier}, instant de départ)
      tlTitleCompetences.to("#anim-title-competences", 2, {left: '28%', opacity: '0.8'}, 0)

    },

    //Animations avec ScrollReveal
    reveal: function() {
      const sr = ScrollReveal({
        duration: 500,
        easing: 'ease-in-out'
      })

      sr.reveal('.secondary-title', {
        scale: 0.7,
      })

      sr.reveal('.sr-info', {
        origin: 'left',
        delay: 200,
        distance: '35px'
      }, 150)

      sr.reveal('.sr-info p', {
        duration: 1000,
        origin: 'left',
        delay: 450,
      }, 200)
    },

  }
}
</script>


<style lang="scss">

</style>
