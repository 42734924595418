<template>
  <div id="realisation-section">
    <ul class="background-fixed">
      <li></li>
      <li class="anim-parallax-reas" data-speed="50">
        <img src="@/assets/realisations/moon.svg" />
      </li>
      <li class="stars anim-parallax-reas" data-speed="5"></li>
      <li class="stars anim-parallax-reas" data-speed="10"></li>
      <li class="stars anim-parallax-reas" data-speed="15"></li>
      <li class="stars anim-parallax-reas" data-speed="20"></li>
    </ul>
    <div class="nav-menu">
      <nav-menu></nav-menu>
    </div>
    <realisations-content></realisations-content>
  </div>
</template>



<script>
import NavMenu from "../components/Nav-menu";
import RealisationsContent from "../components/Realisations-content";

export default {
  name: "Realisations",
  components: {
    "nav-menu": NavMenu,
    "realisations-content": RealisationsContent,
  },
  methods: {
    //Intérsections viewport pour les éléments avec la classe reveal
    isVisible: function () {
      const ratio = 0.8;
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: ratio,
      };

      const handleIntersect = (entries) => {
        entries.forEach(function (entry) {
          if (entry.intersectionRatio >= ratio) {
            entry.target.classList.add("reveal-visible");
          } else {
            entry.target.classList.remove("reveal-visible");
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersect, options);
      const reveal = document.querySelectorAll(".reveal");
      reveal.forEach(function (r) {
        observer.observe(r);
      });
    },

    //Création des barres du menu en fonction du nombre de réalisations
    navBar: function () {
      const reals = document.querySelectorAll(".real");
      reals.forEach(function () {
        let navBar = document.querySelectorAll(".navbar");
        navBar.forEach(function (nav) {
          let section = document.createElement("li");
          section.className = "nav";
          nav.appendChild(section);
        });
      });
    },

    //Parallax étoiles et lune

    parallax: function () {
      document.addEventListener("mousemove", (e) => {
        document.querySelectorAll(".anim-parallax-reas").forEach((layer) => {
          const speed = layer.getAttribute("data-speed");
          const x = (window.innerWidth - e.pageX * speed) / 350;
          const y = (window.innerHeight - e.pageY * speed) / 350;
          layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
      });
    },
  },

  mounted: function () {
    this.isVisible();
    this.navBar();
    this.parallax();
  },
  beforeMount: function () {},
};
</script>

<style lang="scss" scoped>
@import "../scss/components/realisations.scss";
@import "../scss/components/realisations_content.scss";
</style>
