<template>

  <div class="large-container">

    <div class="nav-container">
      <router-link to="/">
        <a class="logo"></a>
      </router-link>
      <nav>
        <ul>
          <router-link to="/">
            <li class="low-title">Accueil</li>
          </router-link>
          <router-link to="/realisations">
            <li class="low-title">Réalisations</li>
          </router-link>
          <router-link to="/contact">
            <li class="low-title contact">Contact</li>
          </router-link>
        </ul>
      </nav>
      <div @click="animationBurger" class="burger">
        <span></span>
      </div>
      <div v-if="burgerActive" class="navigation-burger" id="test">
        <ul>
          <router-link to="/">
            <li @click="animationBurger" class="low-title">Accueil</li>
          </router-link>
          <router-link to="/realisations">
            <li @click="animationBurger" class="low-title">Réalisations</li>
          </router-link>
          <router-link to="/contact">
            <li @click="animationBurger" class="low-title contact">Contact</li>
          </router-link>
        </ul>
      </div>
    </div>

  </div>

</template>



<script>

  export default {
    name:'NavMenu',
    data() {
      return {
        burgerActive: false
      }
    },
    methods: {
      animationBurger: function() {
        const burger = document.querySelector('.burger')
        burger.classList.toggle('active')
        const navigationBurger = document.querySelector('.navigation-burger')
        if (this.burgerActive) {
          document.body.style.overflowY = 'scroll'
          navigationBurger.style.opacity = '0'
          setTimeout(() => {
            this.burgerActive = false
          }, 300);
        } else {
          document.body.style.overflowY = 'hidden'
          this.burgerActive = true
        }
      }
    }
  }
</script>



<style lang="scss" scoped>

@import '../scss/components/nav_menu.scss';


</style>