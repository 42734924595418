<template>
  <div class="real real5">
    <ul class="navbar reveal"></ul>
    <div class="real-infos">
      <h1 class="medium-title medium reveal">App Météo</h1>
      <div class="rea-content">
        <div>
          <div class="line-rea"></div>
          <div class="description-rea reveal">Site web</div>
          <a href="http://jolan.fr/weather" target="_blank" class="button">Voir le projet</a>
        </div>
        <a href="http://jolan.fr/weather" target="_blank" class="img-rea reveal"></a>
      </div>
      <div class="primary-title reveal">04</div>
    </div>
  </div>
</template>


<script>


  export default {
    name:'Weather',
  }

</script>

<style lang="scss">

</style>