<template>
  <div id="page-contact">
    <nav-menu></nav-menu>
    <h1 class="medium-title first">Contactez-moi</h1>

    <form class="medium-container" @submit.prevent="sendEmail">
      <div class="contact-group">
        <div class="left">
          <div class="group">
            <label for="surname">Prénom</label>
            <input v-model="surname" type="text" name="user_surname" id="surname">
          </div>
          <div class="group">
            <label for="name">Nom</label>
            <input v-model="name" type="text" name="user_name" id="name">
          </div>
        </div>
        <div class="right">
          <div class="group">
            <label for="mail">Email</label>
            <input type="email" v-model="email" name="user_email" id="mail">
          </div>
          <div class="group">
            <label for="object">Objet</label>
            <input v-model="object" type="text" name="object" id="object">
          </div>
        </div>
      </div>
      <div class="group group-message">
        <label for="message">Message</label>
        <textarea id="message" v-model="message" name="message"></textarea>
      </div>
      <div class="group group-submit">
        <input type="submit" class="button" value="Envoyer">
      </div>

      <div v-if="success" class="alert alert-success">
        <p class="paragraphe">Votre email a bien été envoyé</p>
      </div>
      <div v-if="error" class="alert alert-error">
        <p class="paragraphe">Malheureusement, l'email n'a pas pu être envoyé</p>
      </div>
    </form>


    <h1 class="medium-title">Coordonnées</h1>
    <div class="medium-container">
      <div class="contact-group coordonnees">
        <div class="left">
          <i class="fas fa-envelope"></i>
          <p class="paragraphe">jolan.callens@gmail.com</p>
        </div>
        <div class="right">
          <i class="fas fa-phone"></i>
          <p class="paragraphe">06 42 21 58 57</p>
        </div>
      </div>
    </div>
    <footer-contact></footer-contact>
  </div>
</template>

<script>
import NavMenu from '@/components/Nav-menu'
import FooterContact from '../components/Footer-contact'
import emailjs from 'emailjs-com'

  export default {
    name: 'Realisations',
    components: {
      'nav-menu': NavMenu,
      'footer-contact': FooterContact
    },
    data() {
      return {
        surname: '',
        name: '',
        email: '',
        object: '',
        message: '',
        success: false,
        error: false
      }
    },
    methods: {
      sendEmail: function(e) {
        if(this.name != '' && this.email != '' && this.message != '') {
          emailjs.sendForm('service_9xuewqx', 'template_229inmg', e.target, 'user_UgQGg3eTgOZE9KxiWEe1x')
            .then(() => {
                this.success = true
                this.error = false
                setTimeout(() => {
                  this.success = false
                }, 4000);
            }, () => {
                this.error = true
                this.success = false
                setTimeout(() => {
                  this.error = false
                }, 4000);
            });
            e.target.reset()
        }
        else {
          this.error = true
          this.success = false
          setTimeout(() => {
            this.error = false
          }, 4000);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '@/scss/components/contact.scss';

</style>
