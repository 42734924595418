<template>
  <div class="competence-category competence-category-video">
    <ul>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">PREMIÈRE PRO</p>
            <p class="paragraphe">80%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">AFTER EFFECTS</p>
            <p class="paragraphe">70%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">FL STUDIO</p>
            <p class="paragraphe">70%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">LIGHTROOM</p>
            <p class="paragraphe">60%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">CINEMA 4D</p>
            <p class="paragraphe">40%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "CompetenceVideo",
  mounted() {
    this.animation();
  },
  props: ["animation"],
};
</script>

<style lang="scss">
</style>