<template>

  <div class="competence-category competence-category-graph">
    <ul>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">ILLUSTRATOR</p>
            <p class="paragraphe">80%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">ADOBE XD</p>
            <p class="paragraphe">70%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">FIGMA</p>
            <p class="paragraphe">70%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
      <li>
        <div class="content_responsive">
          <div class="icon"></div>
          <div class="competence-info">
            <p class="paragraphe">PHOTOSHOP</p>
            <p class="paragraphe">50%</p>
            <div class="progress-bar"></div>
          </div>
        </div>
        <div class="progress-bar"></div>
      </li>
    </ul>
  </div>

</template>


<script>
  export default {
    name:'CompetenceGraph',
    props: ['animation'],
    mounted() {
      this.animation();
    }
  }
</script>

<style lang="scss">


</style>