<template>
  <div id="realisation-brigade">

    <nav-menu></nav-menu>

    <div class="medium-container">

      <h1 class="medium-title first">Brigade Fantôme</h1>
      <p class="information-important">Ce site a été réalisé en Wordpress pour des clients. Cependants, il n'est désormais plus en ligne sur leur serveur pour des raisons inconnues.</p>

      <router-link to="/realisations" class="button">
          Retour aux réalisations
      </router-link>
    </div>



  </div>
</template>


<script>
import NavMenu from "../../components/Nav-menu";

export default {

  name: "Brigade project",

  components: {
    "nav-menu": NavMenu,
  },

  methods: {
     parallax: function () {
      document.addEventListener("mousemove", (e) => {
        document.querySelectorAll(".anim-parallax-reas").forEach((layer) => {
          const speed = layer.getAttribute("data-speed");
          const x = (window.innerWidth - e.pageX * speed) / 350;
          const y = (window.innerHeight - e.pageY * speed) / 350;
          layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
      });
    }
  },

  mounted: function () {
    this.parallax();
  },

}

</script>

<style lang="scss" scoped>
@import '@/scss/components/realisations_brigade.scss';
</style>
