<template>
  <div class="real real4">
    <ul class="navbar reveal"></ul>
    <div class="real-infos">
      <h1 class="medium-title medium reveal">CKOA - MMI</h1>
      <div class="rea-content">
        <div>
          <div class="line-rea"></div>
          <div class="description-rea reveal">Motion design</div>
          <a href="https://www.youtube.com/watch?v=uMD-ko-dSAI" target="_blank" class="button">Voir le projet</a>
        </div>
        <a href="https://www.youtube.com/watch?v=uMD-ko-dSAI" target="_blank" class="img-rea reveal"></a>
      </div>
      <div class="primary-title reveal">03</div>
    </div>
  </div>
</template>


<script>


  export default {
    name:'Ckoa',
  }

</script>

<style lang="scss">

</style>