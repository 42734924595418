<template>
  <div>
    <nav-menu></nav-menu>
    <div class="page-not-found">
      <div class="large-container container-404">
        <h1 class="primary-title">404</h1>
        <h2 class="low-title">Oups... On dirait que la page que vous recherchez est inaccessible.</h2>
        <router-link to="/">
          <div class="button">Retour à l'accueil</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import NavMenu from '@/components/Nav-menu'

  export default {
    name:'PageNotFound',
    components: {
      'nav-menu': NavMenu
    }
  }
</script>

<style lang="scss" scoped>

@import '@/scss/components/404.scss';

</style>